import React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import {
    getCompatibleBrowserLanguage,
    getTranslatedItems
} from '../utils/translation'

const PageHeader = <Header title="404" />

const translations = {
    de: { message: 'Diese Seite extistiert nicht oder nicht mehr.', buttonText: 'Zur Startseite' },
    en: { message: 'This page does not or no longer extist.', buttonText: 'To the home page' }
}

const NotFoundPage = ({ location }) => {
    const lang = getCompatibleBrowserLanguage()
    const translatedItems = getTranslatedItems(translations, lang)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/' }]} pageLang={lang} pageTitle="404" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    {translatedItems.message}{" "}
                    <Link to="/">{translatedItems.buttonText}</Link>.
                </section>
            </Container>
        </Layout>
    )
}

export default NotFoundPage
